// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/drone/src/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-avisos-importantes-js": () => import("/drone/src/src/templates/avisos-importantes.js" /* webpackChunkName: "component---src-templates-avisos-importantes-js" */),
  "component---src-templates-contacto-page-js": () => import("/drone/src/src/templates/contacto-page.js" /* webpackChunkName: "component---src-templates-contacto-page-js" */),
  "component---src-templates-encuesta-js": () => import("/drone/src/src/templates/encuesta.js" /* webpackChunkName: "component---src-templates-encuesta-js" */),
  "component---src-templates-footer-js": () => import("/drone/src/src/templates/footer.js" /* webpackChunkName: "component---src-templates-footer-js" */),
  "component---src-templates-faq-page-js": () => import("/drone/src/src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-legal-page-js": () => import("/drone/src/src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-info-page-js": () => import("/drone/src/src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-pagos-page-js": () => import("/drone/src/src/templates/pagos-page.js" /* webpackChunkName: "component---src-templates-pagos-page-js" */),
  "component---src-templates-privilegios-page-js": () => import("/drone/src/src/templates/privilegios-page.js" /* webpackChunkName: "component---src-templates-privilegios-page-js" */),
  "component---src-templates-requisitos-page-js": () => import("/drone/src/src/templates/requisitos-page.js" /* webpackChunkName: "component---src-templates-requisitos-page-js" */),
  "component---src-pages-404-js": () => import("/drone/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("/drone/src/src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("/drone/src/src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("/drone/src/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/drone/src/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */)
}

